import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Header>Good software matters.</Header>
      <p>
        In this day and age, quality apps and websites are quickly becoming an
        integral part of growing your brand.
      </p>
      <p>
        <b>Yet it's so tough to get them right.</b>
      </p>
      <p>
        We aim to make accessible the creation of bespoke websites and apps for
        small, home-run businesses and independent creators/artists.{" "}
      </p>
      <p>
        <b>Software should evolve around your needs.</b> Not the other way
        around. Often times website builders serve up ready-made, cookie-cutter
        solutions that don't feel intimate for the users.
      </p>
      <p>
        Triomic was founded in 2019 by <a href="https://sean.place">Sean</a> and{" "}
        <a href="https://ravern.zone">Ravern</a> to provide ad-hoc software
        development services. Since then, we've collaborated{" "}
        <b>completely remotely</b> with our partners and clients from different
        parts of the world, building and shipping web/mobile applications.
      </p>
      <b>Find out what we can do for you.</b>
      <p>
        We're currently based in Singapore. Contact us at{" "}
        <a href="mailto:hello@triomic.studio">hello@triomic.studio</a>.
      </p>
    </Layout>
  );
};

const Header = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 20px;
  }
`;

export default IndexPage;
